@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.country-data-container {
  width: 80%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  .gcal-btn-container {
    margin: auto;
    text-align: center;

    .gcal-btn {
      background-color: $primary-color;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      width: 100%;

      &:disabled {
        background-color: $secondary-color;
      }
    }
  }

  select {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  .gcal-warning {
    padding: 4px 8px;
    margin-bottom: 8px;
    background-color: red;
    color:white;
    margin-left: 8px;
    margin-right: 8px;
  }
}