@import '../../css/styles/colors';
@import '../../css/styles/variables';

.navi-logo{
  height: 60px;
  position: relative;
  display: flex;
  
  background-color: #533FA6;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 12px;
  >a {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (min-width: $desktop-width) {
  .navi-main.toggle-width-active{
    .navi-logo{
      padding: 4px;
      > a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        > .text{
          display: none;
        }
      }
    }
  }
}
