@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.projects{
  .projects-container{
    display: flex;
    .projects-container-left{
      width: 160px;
      margin: 0 20px 0 0;
      .project-sort{
        li{
          border-radius: 3px;
          margin: 0 0 4px;
          padding: 8px;
          cursor: pointer;
        }
        li:hover,
        .active{ 
          background-color: $primary-color; 
          color: #FFF;
        }
      }
      .project-category {
        padding-top: 5px;
        height: 600px;
        overflow-y: scroll;
        background-color: #f3f7fc;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
        .all-category {
          position: sticky;
          top: 0;
          background-color: #f3f7fc;
        }
        li{
          border-radius: 3px;
          margin: 0 0 4px;
          padding: 8px;
          cursor: pointer;
        }
        li:hover,
        .active{ 
          background-color: $primary-color; 
          color: #FFF;
        }
      }
    }
    .projects-container-center{
      flex: 1;
      display: flex;
      flex-direction: column;
      .projects-container-top{
        display: flex;
        .projects-search{
          flex: 1;
          padding: 0 20px 0 0;
          form{
            display: flex;
            height: 40px;
            input {
              width: 100%;
            }
            select {
              border-color: rgba(220,220,220,1);
              margin: 0 0 0 -1px;
            }
            .search-projects {
              height: 100%;
            }
            .select-users {
              width: 100%;
              input {
                height: 26px;
              }
            }
            .button-element {
              margin: 0 0 0 8px;
              max-width: 80px;
            }
            .hide {
              display: none;
            }
          }
        }
      }
      .projects-main{
        margin: 20px 0 0 0;
        background-color:#FFF;
        border-radius: 3px;
        overflow: hidden;
        table{
          width: 100%;
          border-collapse:collapse;
          thead{
            background-color: #f3f7fc;
          }
          tr{
            &:not(.header){
              cursor: pointer;
              &:hover{
                box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
              }
            }
            a{
              display: block;
              padding: 8px 0;
            }
            width: 100%;
            th,td{
              text-align: center;
              height: 48px;
              padding: 2px;
            }
          }
        }
        .no-results{
          padding: 20px;
        }
        .projects-main-footer{
          height: 56px;
          padding: 0 8px;
          background-color: #f3f7fc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button-element {
            width: 120px;
          }
        }
      }
    }
  }
}