@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');

.sign-up-page-ph{
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url('../../../../css/imgs/background.jpg');
  background-position: 0% 0%;
  background-repeat:repeat;
  background-size:cover;
  overflow-y: scroll;
  z-index: 200;
  .info-title{
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    margin-left: 30%;
    padding: 40px 10% 0 0;
    text-align: right;
    @media screen and (max-width: 780px) {
        text-align: right;
        padding: 20px;
        margin: 0;
    }
    @media screen and (max-width: 430px) {
        font-size: 36px;
    }
    @media screen and (max-width: 330px) {
        font-size: 28px;
        
    }
  }
  .sign-up-description{
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin: -20px 0 30px 30%;
    padding-right: 10%;
    text-align: right;
    @media screen and (max-width: 780px) {
        font-size: 15px;
        text-align: right;
        padding-right: 20px;
    }
  }
  .section-title-top{
    font-size: 25px;
    font-weight: 500;
  }
  .section-title{
    font-size: 25px;
    font-weight: 500;
    padding-top: 40px;
  }
  .sign-up-form{
    z-index: 1;
    background-color: #fff;
    border-radius: 20px;
    margin: 20px 10% 5% 10%;
    padding: 40px;
    position: relative;
    height: fit-content;
    @media screen and (max-width: 780px) {
        margin: 20px;
    }
    .back-button {
      height: 48px;
      width: 48px;
      top: 15px;
      left: 15px;
      position: absolute;
      border-radius: 2px;
      padding: 8px;
      cursor: pointer;
      svg{
        height: 100%;
        width: 100%;
        .real-path{
          fill: $primary-color;
        }
      }
      z-index: 150;
      &:hover{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
      }
      &:active{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);
      }
    }
    .text-input{
      background-color:$background-color;
      font-weight: 500;
      font-size: 15px;
      border-radius: 20px;
    }
    .dob-input{
      display: flex;
      flex-direction: row;
      justify-self: flex-start;
      select {
        width: 20%;
        min-width: 80px;
        background-color:$background-color;
        font-weight: 500;
        font-size: 15px;
        border-radius: 20px;
        margin-right: 20px;
      }
      &:last-child {
        margin: 0;
      }
    }
    label{
      font-size: 15px;
      font-weight: 500;
      color: rgb(130, 130, 130);
      padding-bottom: 5px;
    }
    select{
      height: 40px;
      width: 40%;
      border-radius: 10px;
      margin: 0 0 12px;
      border: 1px solid rgb(220, 220, 220);
      text-indent: 18px;
    }
    .policies {
      .point {
        margin: 20px 0;
        .link {
          color: gray;
          text-decoration: underline;
        }
        .link:hover {
          color: #aaa;
        }
        .text {
          padding-left: 14px;
        }
      }
    }
    .agreements {
      .subsection-title{
        font-weight: 700;
        padding-top: 15px;
        margin: 30px 0;
        text-decoration: underline;
        font-size: 20px;
      }
      .link {
        color: rgb(87, 87, 87);
        text-decoration: underline;
      }
      .link:hover {
        color: gray;
      }
    }
    .bolded-agreement{
      color: rgb(87, 87, 87);
    }
    .signature-section {
      .subsection-title{
        font-weight: 700;
        padding-top: 20px;
        font-size: 20px;
        margin-top: 50px;
      }
      .signatures {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1150px) {
          flex-direction: column;
        }
        .signature {
          margin: 20px 0;
          width: 50%;
          height: 150px;
          display: flex;
          align-items: center;
          flex-direction: row;
          @media screen and (max-width: 1150px) {
            width: 100%;
          }
          @media screen and (max-width: 520px) {
            flex-direction: column;
          }
          .sig-title {
            font-size: 18px;
            min-width: 90px;
          }
          .sig-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 20px;
            height: 100%;
            min-width: 300px;
            border-radius: 10px;
            background-color:  #eee;
            cursor: pointer;
            img {
              height: 100%;
            }
          }
        }
      }
    }
    .success-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: $primary-color;
      h2.welcome-info-pretitle{
        font-size: 20px;
        margin: 0;
      }
      .welcome-info-title{
        margin: -25px 0 -20px 0;
        font-family: Titillium Web,sans-serif;
        font-size: 75px;
        @media screen and (max-width: 400px) {
          margin: -20px 0 -20px 0;
          font-size: 65px;
        }
        @media screen and (max-width: 350px) {
          margin: -20px 0 -20px 0;
          font-size: 55px;
        }
        @media screen and (max-width: 300px) {
          margin: -20px 0 -10px 0;
          font-size: 40px;
        }
      }
      .email-sent {
        margin: 30px;
        font-size: 32px;
        text-align: center;
      }
      .user-email {
        margin-bottom: 30px;
      }
      .check-email {
        font-size: 18px;
        color: black;
        font-weight: 600;
        max-width: 800px;
        text-align: center;
      }
      .spam-email {
        font-size: 14px;
        color: black;
        max-width: 800px;
        text-align: center;
      }
      .resent-email {
        width: 200px;
        border-radius: 20px;
        .background{
          background-color: #7353ff;
        }
        button {
          padding: 0;
        }
      }
      .welcome-video {
        width: 100%;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        iframe {
          width: 100%;
          min-width: 300px;
          min-height: 300px;
          aspect-ratio: calc(16 / 9);
          border-radius: 10px;
        }
      }
    }
    .signature-pad {
      position: fixed;
      top: 0;
      left: 0;
      min-height: 100vh;
      min-width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      .backdrop {
        position: fixed;
        background-color: black;
        opacity: 0.5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .canvas-ctn {
        position: relative;
        z-index: 101;
        width: 100%;
        max-width: 600px;
        height: 300px;
        .ok-btn {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 3px;
          cursor: pointer;
          background-color: #eee;
          svg {
            fill: green;
            width: 36px;
            height: 36px;
          }
          &:hover{
            box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
          }
          &:active{
            box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);
          }
        }
        .sig-canvas {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}