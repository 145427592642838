@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.users-comp{
  // *{border: 1px solid black;}
  .users-container{
    display: flex;
    .users-container-left{
      width: 140px;
      margin: 0 20px 0 0;
      .user-sort{
        li{
          border-radius: 3px;
          margin: 0 0 4px;
          padding: 8px;
          cursor: pointer;
        }
        li:hover,
        .active{ 
          background-color: $primary-color; 
          color: #FFF;
        }
      }
    }
    .users-container-center{
      flex: 1;
      display: flex;
      flex-direction: column;
      .user-sign-up-banner {
        width: 100%;
        background-color: #f3f7fc;
        border-radius: 8px;
        padding: 36px;
        margin: 18px 0 30px;
        border: rgba(103, 79, 204, 0.2) solid 1px;
        .user-sign-up-heading {
          font-size: 18px;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 28px;
          margin-bottom: 8px;
          font-weight: 500;
          .sign-up-code {
            cursor: pointer;
            background-color: transparent;
            border: 0;
            font-size: 18px;
            margin: 0 6px;
            font-weight: 600;
            svg {
              margin-left: 8px;
              width: 15px;
              height: 15px;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
        .user-sign-up-subtitle {
          font-size: 15px;
  
        }
      }
      .users-container-top{
        display: flex;
        .users-search{
          flex: 1;
          form{
            height: 40px;
            display: flex;
            select{
              border-color: rgba(220,220,220,1);
              margin: 0 0 0 -1px;
            }
            .button-element {
              margin: 0 0 0 8px;
              max-width: 80px;
            }
          }
        }
        .users-add{
          margin-left: 8px;
        }
        .bulk-add {
          display: flex;
          flex-direction: row;
          align-items: center;
          .button-element {
            width: 148px;
            button {
              padding: 0;
            }
          }
        }
      }
      .users-main{
        margin: 20px 0 0 0;
        background-color:#FFF;
        border-radius: 3px;
        overflow: hidden;
        table{
          width: 100%;
          border-collapse:collapse;
          thead{
            background-color: #f3f7fc;
          }
          tr{
            &:not(.header){
              cursor: pointer;
              &:hover{
                box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
              }
            }
            width: 100%;
            th,td{
              text-align: center;
              height: 48px;
            }

            .username-info{
              display: flex;
              align-items: center;
              img,.svg-container {
                margin: 0 8px;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: rgba(220,220,220,1);
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
              }
              .svg-container{
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                  height: 24px;
                  width: 24px;
                  .real-path{
                    fill: #555;
                  }
                }
              }
            }
          }
        }
        .no-results{
          padding: 20px;
        }
        .users-main-footer{
          height: 56px;
          padding: 0 8px;
          background-color: #f3f7fc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button-element {
            width: 80px;
          }
        }
        .users-button-load-div{
          display: flex;
          flex-direction: row;
          height: 56px;
          padding: 0 8px;
          align-items: center;
        }
      }
    }
  }
}