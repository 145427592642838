.draggable-user {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  z-index: 200;
  max-width: 92px;
  cursor:pointer;
  .user-name {
    display: flex;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    margin-top: 4px;
    padding: 2px 10px;
    color: #fff;
    background-color: rgba(58, 58, 58, 0.5);
    justify-content: center;
    align-items: center;
  }
  &:active {
    cursor: grab;
  }
  .user-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50px;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    .user-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .image {
        width: 40px;
        height: 40px;
        pointer-events: none;
        border-radius: 50%;
      }
      .visitor-image {
        width: 32px;
        height: 32px;
        pointer-events: none;
      }
    }
  }
}