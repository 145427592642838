@import '../../../../../css/styles/colors';
@import '../../../../../css/styles/variables';

.modal :first-child.sensor-data-modal{
  min-width: 60%;
  min-height: 30%;
  max-height: 80vh;
  padding: 0;
  border-radius: 24px;
  .modal-close-button {
    border-radius: 50%;
    top: 8px;
    right: 8px;
    background-color: rgba(0,0,0,.2);
    width: 40px;
    height: 40px;
    &:hover{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.4);
    }
    &:active{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.5);
    }
    .real-path {
      fill: #fff;
    }
  }
  .sensor-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin: 20px 0;
    text-align: center;
    padding: 0px 20px 30px;
  }
  .reading-data-text {
    margin: 0 0 5px;
    font-size: 25px;
    width: 90%;
  }
  .description {
    margin: 5px 0;
    font-size: 18px;
  }
}
