@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.institution-content{
  flex: 1;
  .institution-header{
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
  .institution-details {
    margin: 20px auto;
    width: 80%;
    .institution-ctn {
      width: 100%;
      margin: 20px auto;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
      .institution-detail {
        .emails-container {
          padding: 10px;
          margin-top: 20px;
          border-radius: 5px;
          box-shadow: 0px 0px 8px 0px rgba(200,200,200,1);
          .email-input-item {
            display: flex;
            .button-element {
              margin: 0 0 0 12px;
              width: 40px;
              min-width: 40px;
              button{
                padding: 0;
                color: rgba(0,0,0,0);
              }
            }
          }
          .add-button {
            margin-bottom: 15px;
            width: 40px;
            min-width: 40px;
            button{
              padding: 0;
              color: rgba(0,0,0,0);
            }
          }
        }
        label {
          font-size: large;
          font-weight: bold;
        }
        .input-item {
          margin: 20px 0;
          display: flex;
          .button-element {
            margin: 0 0 0 12px;
            width: 40px;
            min-width: 40px;
            button{
              padding: 0;
              color: rgba(0,0,0,0);
            }
          }
        }
      }
    }
    .chat-function-container {
      margin: 30px 0;
    }
    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #ccc;
      outline: none;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    input[type="radio"]:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
