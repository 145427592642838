
// Color palet
// 1 - #30C5FF - SPIRO DISCO BALL
// 2 - #2A2D34 - GUNMETAL
// 3 - #5C946E - RUSSIAN GREEN
// 4 - #80C2AF - CAMBRIDGE BLUE
// 5 - #A0DDE6 - NON-PHOTO BLUE

$primary-color: #533FA6;
$secondary-color: #674FCC;
$accent-color: #A0DDE6;

$background-color: #fff;
$light-gray-color: rgba(200,200,200,1);
$gray-screen-background-color: rgba(0,0,0,.5);
