@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');

.check-in-check-out-global {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url('../../../../css/imgs/background.jpg');
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: cover;
  overflow-y: scroll;
  z-index: 200;
  display: flex;
  justify-content: center;
  .space-button {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
}

.check-in-forms {
  // for large screen -> cisco board
  // position: absolute;
  // bottom: 10%;
  // left: 10%;
  z-index: 1;
  background-color: #fff;
  border-radius: 20px;
  margin: auto;
  width: 80%;
  padding: 40px;
  height: fit-content;
  @media screen and (max-width: 780px) {
    margin: 20px;
  }
  .sign-up-forms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title-container{
      margin: 10px 0 50px;
    }
  }
  .info-title {
    font-size: 40px;
    font-weight: 600;
    color: $primary-color;
    text-align: center;
    @media screen and (max-width: 780px) {
      text-align: right;
      padding: 20px;
      margin: 0;
    }
    @media screen and (max-width: 430px) {
      font-size: 36px;
    }
    @media screen and (max-width: 330px) {
      font-size: 28px;
    }
  }
  .info-subtitle{
    font-size: 20px;
    font-weight: 600;
    color: rgba(51, 64, 77, 0.8);
    text-align: center;
  }
  .info-text{
    font-size: 32px;
    font-weight: 600;
    color: $primary-color;
    text-align: center;
  }
  .vivinaut-visitor-greeting-container{
    margin: 0 0 40px;
  }
  .vivinaut-visitor-greeting-title {
    font-size: 30px;
    font-weight: 600;
    color: $primary-color;
    @media screen and (max-width: 780px) {
      text-align: right;
      padding: 20px;
      margin: 0;
    }
    @media screen and (max-width: 430px) {
      font-size: 36px;
    }
    @media screen and (max-width: 330px) {
      font-size: 28px;
    }
  }
  .vivinaut-visitor-greeting-subtitle{
    margin: 5px 0 0;
    color: rgba(51, 64, 77, 0.8);
  }
  .back-button {
    top: -25px;
    right: 34px;
    position: absolute;
    border-radius: 20px;
    padding: 8px;
    cursor: pointer;
    z-index: 150;
    width: 80px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    margin: 50px 10px 0 0;
    color: white;
    background-color: #7353ff;
    border: 1px;
    &.checkout {
      color: #7353ff;
      background-color: white;
      right: 130px;
      width: 100px;
      border: none;
      box-shadow: 0 0 0 2px #7353ff inset;
    }
  }
  .text-input {
    background-color: $background-color;
    font-weight: 500;
    font-size: 15px;
    border-radius: 20px;
  }
  label {
    font-size: 15px;
    font-weight: 500;
    color: rgb(130, 130, 130);
    padding-bottom: 5px;
  }
  .error-msg {
    font-size: 17px;
    font-weight: 500;
    color: red;
  }
  .list-of-users {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .user {
    background-color: #fff;
    border-radius: 10px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    width: 45%;
    margin: 20px 20px 10px 15px;
    box-shadow: 5px 5px #d7d4d4;
    img {
      width: 50px;
      border-radius: 50%;
    }
    .text {
      margin: 20px 10px 10px 10px;
    }
  }
  @media (min-width: 640px) and (max-width: 1024px) {
    .user {
      margin: 20px 0 0 10px;
    }
  }
  @media (max-width: 639px) {
    .user {
      width: 90%;
    }
  }

  .identity-buttons {
    text-align: center;
    .identity-button {
      border-radius: 20px;
      width: 40%;
      height: 180px;
      margin: 30px;
      background-color: #7353ff;
      color: white;
      font-size: 40px;
      font-weight: 600;
      border: 1px;
      box-shadow: 8px 8px #b6aaeb;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      &:hover{
        background-color:rgb(114, 109, 253);
        transform: scale(1.05);
      }
      @media (max-width: 639px) {
        width: 90%;
        margin: 0px;
        &.visitor {
          margin-top: 20px;
        }
        &.vivinaut {
          margin-bottom: 20px;
        }
      }
      @media (min-width: 640px) and (max-width: 1024px) {
        margin: 20px;
      }
    }
  }
}

.checkin-popup {
  padding: 20px;
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin: 10px 5px;
    vertical-align: middle;
  }
  h1 {
    text-align: center;
  }
  .profile {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
      width: 50px;
      border-radius: 50%;
    }
    .text {
      margin: 10px 0 0 10px;
      color: #183b56;
    }
  }
  .visitor-info {
    margin: 24px 0;
    .visitor-info-text {
      margin: 0 0 12px 0;
      font-size: 14px;
    }
  }
  .button-container {
    text-align: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .modal-btn {
      cursor: pointer;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      margin: 50px 10px 0 0;
      color: white;
      background-color: #7353ff;
      border: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled{
        opacity: 0.5;
      }
    }
    .modal-btn-back {
      cursor: pointer;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      margin: 50px 10px 0 0;
      color: #7353ff;
      background-color: white;
      border: 2px solid #7353ff;
    }
  }
}

.checkout-popup {
  padding: 20px;
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin: 10px 5px;
    vertical-align: middle;
  }
  h1 {
    text-align: center;
  }
  .profile {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
      width: 50px;
      border-radius: 50%;
    }
    .text {
      margin: 10px 0 0 10px;
      color: #183b56;
    }
  }
  .button-container {
    text-align: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .modal-btn {
      cursor: pointer;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      margin: 50px 10px 0 0;
      color: white;
      background-color: #7353ff;
      border: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled{
        opacity: 0.5;
      }
    }
    .modal-btn-back {
      cursor: pointer;
      width: 150px;
      height: 40px;
      border-radius: 20px;
      margin: 50px 10px 0 0;
      color: #7353ff;
      background-color: white;
      border: 2px solid #7353ff;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: #3f4d5a;
  }

  .form-option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  select {
    &:focus-visible {
      outline: none;
    }
  }

  fieldset {
    border: 1px solid #dedeea;
    padding: 14px 18px;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 700;
    transition: border-color 0.2s ease-in-out;
    margin-bottom: -12px;
    &:focus-within {
      border-color: #615dfa;
    }

    .form-option {
      display: flex;
      flex-direction: row;
      margin: 5px 0;
      input {
        margin-right: 10px;
      }
    }
  }

  .required-box {
    height: 0;
    border-bottom: none;
    pointer-events: none;
  }
}

.success-popup {
  text-align: center;
  img {
    width: 100px;
    height: 100px;
    margin: 20px;
  }
}

.feedback {
  text-align: center;
  .ratings {
    display: inline-block;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &.selected {
      background-color: lightgray;
    }
  }
  .feedback-box {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    &.email {
      height: 50px;
    }
  }
  .checkout-form {
    label, .multiple {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      color: #3e3f5e;
      font-size: 1.1em;
      h3 {
        margin-bottom: 0;
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
      margin: 12px 0;
      border: 1px solid #dedeea;
      border-radius: 8px;
    }

    select {
      &:focus-visible {
        outline:none;
      }
      padding: 4px 16px;
      margin: 12px 0;
      border: 1px solid #dedeea;
      height: 32px;
      border-radius: 8px;
    }

    fieldset {
      border: 1px solid #dedeea;
      padding: 14px 18px;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      transition: border-color .2s ease-in-out;
      margin: 12px 0 0 0;
      &:focus-within {
        border-color: #615dfa;
      }

      .form-option {
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        input {
          margin-right: 10px;
        }
      }
    }

    .required-box {
      height: 0;
      border-bottom: none;
      border-color: transparent;
      pointer-events: none;
    }
  }
}

.visitor-sessions {
  margin-top: 20px;
  h3 {
    font-weight: 500px;
    color: rgb(130, 130, 130);
    font-size: 18px;
  }
}

.toggle-button {
  cursor: pointer;
  border-radius: 20px;
  margin: 50px 10px 0 0;
  color: white;
  background-color: #7353ff;
  border: 1px;
}

.comments-footer {
  .pagination > div {
    border: 1px solid #7353ff;
    &.active {
      background-color: #7353ff;
    }
    :hover {
      box-shadow: 0 0 0 2px #7353ff inset;
    }
  }
}

.form-content {
  .input-container {
    margin-top: 20px;
    .visitor-names {
      display: flex;
      margin-top: 12px;
      .visitor-name {
        flex: 1;
        padding: 0 48px 0 0;
      }

      @media screen and (max-width: 780px) {
        flex-direction: column;
        .visitor-name {
          padding: 0 0 12px 0;
        }
      }
    }
  }
}

.modal :first-child.check-in-modal {
  overflow-y: auto;
  min-width: 50%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  @media (max-width: 900px) {
    max-width: 80%;
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;
  margin-right: 10px;
  vertical-align: middle;
}

input[type='radio']:checked {
  background-color: grey;
  border-color: grey;
}
