@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.dashboard{
  .welcome-container {
    margin: 120px 80px;
    @media screen and (max-width: $mobile-width) {
      margin: 120px 24px;
    }
    .welcome-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 36px;
      .admin-logo {
        width: 36px;
        height: 36px;
      }
      .welcome-title {
        font-weight: 400;
        font-size: 36px;
        margin: 0 8px;
      }
    }
    .welcome-subtitle {
      font-size: 18px;
      margin-bottom: 22px;
      .link {
        color: #3367d6;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: gray;
        }
      }
    }
    .institution-info {
      display: flex;
      flex-direction: row;
      margin-bottom: 28px;
      .copy {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        margin-right: 30px;
        .copy-title {
          margin-right: 12px;
        }
        .sign-up-code {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          border: 0;
          svg {
            margin-left: 8px;
            width: 15px;
            height: 15px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .public-event-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          svg {
            margin-left: 8px;
            width: 15px;
            height: 15px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .recommendation-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 54px;
      .recommendation-link {
        font-size: 16px;
        color: #3367d6;
        background-color: transparent;
        border: 0;
        border-bottom: #3367d6 solid 1px;
        text-decoration: none;
        margin: 0 18px 18px 0;
        font-weight: 500;
      }
    }

    .recommendation-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 42px;
      svg {
        fill: #3367d6;
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      .recommendation-button {
        font-size: 14px;
        color: #3367d6;
        background-color: #fff;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin: 0 18px 18px 0;
        padding: 6px 8px;
        border-radius: 4px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
        &:active {
          background-color: #eee;
        }
      }
    }
    
    .quick-access {
      .quick-access-title {
        font-size: 22px;
        margin-bottom: 8px;
      }
      .access-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .access-button {
          border: #ddd solid 1px;
          width: 264px;
          height: 90px;
          border-radius: 8px;
          background-color: #fff;
          margin: 0 24px 24px 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          padding: 0 18px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          svg {
            width: 20px;
            height: 20px;
            margin-right: 12px;
          }
          cursor: pointer;
          &:hover {
            background-color: #f2f2f2;
          }
          &:active {
            background-color: #eee;
          }
        }
      }
    }
  }
}
