@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.carousel-header{
  border: 1px solid $light-gray-color;
  border-width: 0px 0px 1px 0px;
  display: flex;
  & > div{
    flex-grow: 1;
    color: #555;
    height: 34px;
    transition: all 0.3s;
    text-align: center;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    &:hover {
      box-shadow: inset 0 0 250px 0 rgba(0,0,0,.1);
    }
    &.active{
      background-color: $primary-color;
      color: #fff;
    }
    & > div {
      height: 100%;
      padding: 10px;
    }
  }
}