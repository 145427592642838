@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";

.welcome-modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 25px;
    text-align: center;
    min-height: 70vh;
}

.page-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    margin: 30px 0;
    border-radius: 20px;
}

.title {
    font-size: 28px;
    margin: 10px 0;
}

.subtitle {
    font-size: 20px;
    margin: 10px;
}

.description {
    margin: 5px;
}

.workshop-item-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    overflow-x: scroll;
    justify-content: space-between;  
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

::-webkit-scrollbar {
    display: none;
  }

.welcome-modal-button {
    margin: 30px 0 0 0;
    width: fit-content;
    padding: 11px 50px;
    border-radius: 20px;
    color: white;
    background-color: #7353ff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    a {
        text-decoration: none;
        color: white;
    }
}