@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');

.gallery-container {
  position: absolute;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .gallery-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .gallery-items {
    transition: all 0.6s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .hide-top {
    transform: translateY(-100vh);
  }

  .hanging-line {
    background-color: #fbcf04;
    width: 2px;
    position: absolute;
  }
  .hanging-svg {
    position: absolute;
  }
  .hanging-animation {
    transform-origin: 50% -50vh;
    animation: hanging 5s linear infinite;
  }
  
  @keyframes hanging {
    from { transform: rotate(0deg) }
    25% { transform: rotate(1deg) }
    75% { transform: rotate(-1deg) }
    100% { transform: rotate(0deg) }
  }
}
