.modal :first-child.project-modal{
  min-width: 60%;
}

.project-modal {
  h1{
    margin: 0 0 60px;
    font-size: x-large;
  }
  h4{
    margin: 24px 0 12px;
    font-size: larger;
  }
  label{
    margin: 0 0 12px;
    font-weight: bold;
    font-size: larger;
  }
  .link {
    margin: 4px 0;
    a {
      text-decoration: underline;
      &:hover {
        color: gray;
      }
    }
  }
  .section {
    margin-bottom: 36px;
    h6 {
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
  .approve-reject{
    // border: 1px solid black;
    .buttons{
      margin: 4px 0 0;
      display: flex;
      .button-element{
        flex: 8;
      }
      .spacer{
        flex: 1;
      }
    }
  }
  .badge-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0;
    padding: 8px 0;
    border-radius: 8px;
    img {
      width: 30px;
      height: 35px;
      margin: 0 20px;
    }
    &:hover {
      background-color: #ddd;
    }
  }
  .hide {
    opacity: 0;
  }
  .project-btn {

  }
  .danger-btn {
    .background {
      background-color: rgba(200,0,50,1);
    }
  }
  .project-authors {
    min-height: 356px;
  }
  .project-authors-ctn {
    flex: 1;
    margin: 15px 0;
    padding: 10px;
    background-color: #e4edf6;
    .project-authors-header {
      height: 56px;
      padding: 0 24px;
      background-color: #f3f7fc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label{
        margin: 0;
        font-weight: bold;
        font-size: larger;
      }
    }
    ul.project-authors-list{
      margin: 0;
      background-color: #fff;
      > li{
        margin: 0;
        padding: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px;
        .author-info{
          display: flex;
          align-items: center;
          img {
            width: 35px;
            height: 35px;
            margin: 0 15px;
            border-radius: 50%;
          }
        }
        .author-right {
          display: flex;
          flex-direction: row;
          .author-role {
            border: 1px #ddd solid;
            border-radius: 4px;
            margin-right: 24px;
          }
          .author-button{
            max-width: 40px;
          }
          .button-element {
            .background {
              background-color: rgba(200,0,50,1);
            }
          }
        }
      }
    }
  }
  .project-owner-container {
    .button-element {
      margin-top: 18px;
      button {
        z-index: inherit;
      }
    }
    .button-status-image {
      z-index: inherit;
    }
  }
  .author-search {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .author-dropdown {
      flex: 1;
    }

    .custom-option {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 35px;
        height: 35px;
        margin: 0 20px;
        border-radius: 50%;
      }
    }

    .button-element {
      margin: auto 0 auto 12px;
      width: 40px;

      min-width: 40px;

      button {
        padding: 0;
        color: rgba(207, 27, 27, 0);
      }
    }
  }
}