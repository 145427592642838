
@import '../../../css/styles/colors';

.entry{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(245,248,250,1) 0%, rgba(195,213,232,1) 100%);

  .entry-header{
    .logo-image {
      -webkit-user-drag: none;
      width: 100%;
      max-width: 400px;
    }
  }

  .sns-button-space{
    height: 18px;
  }
}