@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";

.workshop-item {
  margin: 0;
  height: 80%;
  min-width: 400px;
  max-width: 500px;
  display: flex;
  flex-flow: column;
  margin: 20px 10px;

  .workshop-item-content-container {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 12px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s ease-out;
    box-shadow: 6px 6px 6px 6px $light-gray-color;

    &:hover {
      transform: scale(1.03);
    }

    .workshop-image {
      height: auto;
      display: flex;
      justify-content: center;
      align-self: flex-start;
      margin: 20px 20px 10px;

      .image {
        width: 100%;
        aspect-ratio: 1.1;
        border-radius: 20px;
      }
    }

    .text-container {
      text-align: left;
      padding: 10px 0 25px;
      display: flex;
      flex-flow: column;
      text-align: center;

      .title {
        font-size: 23px;
        font-weight: 500;
      }

      .duration {
        padding-top: 0px;
      }
    }
  }
}