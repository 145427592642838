.player-bar-container {
  position: fixed;
  top: 0px;
  left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  z-index: 199;
  .player-bar {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff66;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease-in-out;
    border: 0;
    cursor: pointer;
    padding: 8px 12px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 80vh;
    .hud-user-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      border-radius: 16px;
      background-color: #fff;
      overflow: hidden;
      white-space: nowrap;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      margin: 8px 0;
      padding: 8px;
      transition: all 0.3s ease-in-out;
      width: 64px;
      min-height: 64px;
      border: 0;
      cursor: pointer;
      .user-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-right: 12px;
        height: 100%;
        padding: 0 6px;
        .user-name {
          font-weight: 500;
          font-size: 18px;
        }
        .user-event {
          width: 100%;
          font-size: 15px;
          margin-top: 2px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
      .user-image {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        min-height: 48px;
        width: 48px;
        height: 48px;
        .image {
          width: 100%;
          height: 100%;
          pointer-events: none;
          border-radius: 50%;
        }
        .visitor-image {
          width: 90%;
          height: 90%;
          pointer-events: none;
        }
      }
    }
    .show {
      width: 300px;
    }
  }
}