.modal :first-child.role-modal{
  max-width: 720px;
}
.role-modal{
  max-height: 95vh;
  .carousel-header{
    margin: 0 0 16px;
  }
  .staff-role-ctn {
    select {
      display: block;
      height: 40px;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
    }
    h2 {
      margin: 20px 0;
    }
    label {
      font-weight: 500;
    }
  }
  .danger-zone {
    height: 100px;
  }
}