@import "../../../../../css/styles/colors";
@import "../../../../../css/styles/variables";

.profile-challenge-item {
  display: flex;
  flex-flow:column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
  .challenge-item-sub {
    max-width: 320px;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-flow:column;
    justify-content: space-between;
    transition: all 0.3s ease-out;
    border: 1px solid rgba(160, 160, 160, 0.3);
    .challenge-checkmark {
      position: absolute;
      top: -6px;
      left: 0;
      font-size: 29px;
      visibility: hidden;
    }
    .challenge-earned {
      position: absolute;
      top: 6px;
      right: 6px;
    }
    .challenge-image {
      display:flex;
      align-items: flex-start;
      overflow: hidden;
      object-fit: cover;
      width: 100%;
      aspect-ratio: 4 / 3;
      .image {
        width: 100%;
        height: 100%;
        min-height: 192px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }
    .challenge-details {
      flex: 1;
      padding: 18px 18px 8px 18px;
      display: flex;
      flex-direction: column;
      .name {
        margin-bottom: 18px;
        .text {
          color: #3e3f5e;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .desc {
        margin: 8px 0;
        color: #a2a2a2;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .creator {
        margin: 0 0 20px 0;
        color: #a2a2a2;
        font-size: 12px;
        overflow-y: hidden;
      }
    }
    .earned-challenge-users {
      width: 100%;
      border: 1px solid #eaeaf5;
      border-width: 1px 0 0 0;
      padding: 12px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 56px;
      .difficulty {
        font-size: 12px;
        font-weight: 500;
        color: $primary-color;
        display:inline-block;
        padding: 8px;
        border-radius: 10px;
        display: flex;
        img {
          height: 10px;
        }
        @media screen and (max-width: 750px) {
          padding: 8px 10px;
        }
      }
      .stars {
        display:inline-block;
        margin-right: 10px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1280px) {
          vertical-align: baseline;
        }
        @media screen and (max-width: 750px) {
          margin-right: 5px;
        }
        @media screen and (max-width: 300px) {
          margin-right: 5px;
        }
      }
      .level {
        display:inline-block;
        flex-wrap: nowrap;
        overflow:hidden;
        @media screen and (max-width: 420px) {
          font-size: 10px;
        }
      }
    }
  }
}
