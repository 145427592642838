@import '../../../../../css/styles/colors';
@import '../../../../../css/styles/variables';

.modal :first-child.profile-modal{
  min-width: 50%;
  padding: 0;
  border-radius: 24px;
  .modal-close-button {
    border-radius: 50%;
    top: 8px;
    right: 8px;
    background-color: rgba(0,0,0,.2);
    &:hover{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.4);
    }
    &:active{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.5);
    }
    .real-path {
      fill: #fff;
    }
  }

  .profile-content-container {
    overflow-y: scroll;
    height: 100%;
    max-height: 80vh;
  }

  .profile-info-banner {
    justify-items: center;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    min-height: 512px;
    .user-cover-image {
      min-height: 20vw;
      width: 100%;
      height: fit-content;
      align-items: center;
      overflow: hidden;
      .image {
        height: 100%;
        width: 100%;
        min-height: 20vw;
        border-radius: 0;
        object-fit: cover;
      }
    }
    .user-profile-basic-info{
      display: flex;
      background-color: #fff;
      padding: 10px;
      height: 120px;
      flex-direction: row;
      justify-content: space-between;
      .user-profile-image-name-container{
        display: flex;
        position: relative;
        width: 250px;
        .user-profile-image-container {
          position: absolute;
          top: -40%;
          width: 128px;
          height: 128px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 60px;
          .user-profile-image {
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            height: 128px;
            width: 128px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 5px #fff solid;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .visitor-image {
            height: 100px;
            width: 100px;
          }
          .user-profile-country {
            width: 36px;
            height: 36px;
            position: absolute;
            bottom: 8px;
            right: 2px;
            border-radius: 50%;
            border: 2px #fff solid;
          }
        }
      }
      .user-data {
        flex: 1;
        max-width: 440px;
        display: flex;
        flex-direction: row;
        padding-top: 18px;
        .divider {
          width: 2px;
          height: 20%;
          background-color: #ddd;
        }
        .data-btn {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          .stat {
            font-weight: 600;
            font-size: 20px;
            margin: 0 0 8px 0;
          }
          .more-button {
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }

    .profile-name-container {
      padding: 0 18px;
      display: flex;
      flex-direction: row;
      margin-bottom: 18px;
      align-items: center;
      .user-real-name {
        margin: auto 0 auto 20px;
        font-weight: 700;
        font-size: 24px;
      }
      .profile-status {
        margin-left: 28px;
        background-color: #eee;
        padding: 4px 12px;
        border-radius: 8px;
        font-size: 14px;
      }
      .profile-status-image {
        width: 24px;
        height: 24px;
        margin-left: 8px;
      }
    }
    .profile-description-container {
      width: 100%;
      margin-bottom: 18px;
      padding: 0 36px;
      .profile-description {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .check-in-info-container {
      display: flex;
      flex-direction: row;
      margin: 0 32px 18px 32px;
      .check-in-info {
        margin: 0 18px 0 0;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 30px;
        width: fit-content;
        border: 1px solid #ddd;
        background-color: #fff;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .profile-section {
    margin: 18px 0 42px;
    padding: 0 36px;
    .section-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .section-title {
        font-size: 18px;
        font-weight: 600;
      }
      .count {
        font-size: 18px;
        font-weight: 600;
        opacity: 0.5;
        margin-left: 8px;
      }
    }
    .badge-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .badge-item {
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        padding: 0 12px;
        .badge-image {
          width: 80px;
          height: 80px;
          .image {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            object-fit: contain;
          }
        }
        .badge-name {
          margin-top: 12px;
          text-align: center;
        }
      }
    }
    .project-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .project-item {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        border: 1px solid rgba(160, 160, 160, 0.5);
        border-radius: 12px;
        overflow: hidden;
        .project-image {
          width: 100%;
          .image {
            width: 100%;
            aspect-ratio: 4 / 3;
            object-fit: cover;
          }
        }
        .project-name {
          margin: 12px 12px 8px;
          font-weight: 600;
          font-size: 18px;
        }
        .project-description {
          margin: 0 12px 12px;
          font-weight: 400;
          font-size: 15px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
      }
    }
    .challenge-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .challenge-item {
        width: 48%;
        margin-top: 24px;
      }
    }
  }
}
