.profile-details{
    .image{
      margin: 0 0 24px 0;
      img{
        max-height: 120px;
      }
      label{
        margin: 8px 0 0 0;
        max-width: 240px;
      }
      input{
        display: none;
      }
    }
}