@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.branch-settings-container {
    .branch-container {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin: 20px auto;
    }
    .branch-settings-section {
      width: 100%;
      background-color: #fff;
      padding: 0 20px 20px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
      .enable-settings-section {
        padding: 20px 0;
      }
      .save-button-container {
        margin-top: 20px;
        text-align: center;
    
        .save-button {
          background-color: $primary-color;
          color: #ffffff;
          border: none;
          border-radius: 4px;
          width: 100%;
    
          &:disabled {
            background-color: $secondary-color;
          }
        }
      }
  
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ccc;
        outline: none;
        margin-right: 10px;
        vertical-align: middle;
      }
      
      input[type="radio"]:checked {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
  