@import '../../css/styles/colors';
@import '../../css/styles/variables';

$icon-padding: 10px;

.navi-body{
  flex: 1;
  .branch-selection {
    height: 44px;
    margin: 0px 8px 8px;
    select{
      border-color: rgba(220,220,220,1);
      width: 100%;
      height: 100%;
      border-radius: 5px;
      padding: 6px;
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }
  }
  > ul{
    list-style: none;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 0px;
    > li{
      margin: 0px 8px 8px;
      
      > a,
      > .logout{
        border-radius: 5px;
        display: flex;
        position: relative;
        color: #FFF;
        padding: 0 0 0 44px;
        height: 44px;
        width: 100%;
        > .icon{
          position: absolute;
          left: 0;
          top:0;
          height: 100%;
          width: 44px;
          padding: 0 $icon-padding;
          svg{
            position: relative;
            height: 100%; width: 100%;
            fill: #fff;
            .real-path{
              fill: #FFF;
              transition: fill 0.3s;
            }
          }
        }
        > .detail{
          flex: 1;
          padding: 6px 0;
          display: flex;
          flex-flow: column;
          .display,
          .desc{
            flex: 2;
            display: flex;
            align-items: center;
          } 
          .desc{
            font-size: 10px;
            flex: 1;
          }
        }
        &:hover, &.active{
          transition: all 0.3s;
          cursor: pointer;
          color: $primary-color;
          background-color: #FFF;
          text-decoration: none;
          > .icon svg {
            fill: $primary-color;
          }
          > .icon svg .real-path{
            fill: $primary-color;
          }
        }
      }
    }
  }
  .line{
    margin:0 4px 8px;
    background:linear-gradient(to right, $primary-color, rgba(255,255,255,1), $primary-color, );
    width: calc(100% - 8px);
    height: 1px;
  }
}
@media screen and (min-width: $desktop-width) {
  .navi-main.toggle-width-active{
    > .navi-body{
      > ul {
        > li{
          > a,
          > .logout{
            > .detail{
              position: absolute;
              left: 58px;
              opacity: 0;
              transition: left 0.3s,opacity 0.3s;
              border-radius: 3px;
              padding: 6px 12px;
              height: 44px;
              width: auto;
              padding: 0;
              width: 0px;
              height: 0px;
              z-index: 100;
              background-color: $primary-color;
              color: #FFF;
              box-shadow: 0px 1px 3px 0px rgba(0,0,0,.2);
              white-space: nowrap;
              overflow: hidden;
            }
          }
          &:hover{
            > a,
            > .logout{
              > .detail {
                overflow: visible;
                left: 64px;
                opacity: 1;
                display: flex;
                padding: 6px 12px;
                height: 44px;
                width: auto;
                &::before{
                  padding: 0px;
                  position: absolute;
                  top: 12px;
                  left: -20px;
                  display: block;
                  content:"h";
                  font-size: 0px;
                  border-top: 10px solid transparent;
                  border-right: 10px solid $primary-color;
                  border-left: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}