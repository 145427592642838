@import '../../../../../css/styles/colors';
@import '../../../../../css/styles/variables';

.project-section-container {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  padding: 18px 24px;
  .project-header {
    min-height: 90px;
    margin-bottom: 4px;
    padding: 20px 0;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .project-title {  
      display: flex;
      align-items: center;
      color: #183b56;
      .title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .subtitle {
        font-size: 15px;
        margin-bottom: 0px;
        font-weight: 500;
      }
      .author {
        display: flex;
        align-items: center;
      }

      .project-creators {
        position: relative;
        width: 72px;
        height: 72px;
        margin: 0 20px 0 0;
        min-width: 72px;
        overflow: hidden;
        :first-child {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 50px;
          height: 50px;
          border: 2px solid #fff;
          border-radius: 50%;
        }
        :last-child {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 50px;
          height: 50px;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
      .profile-image {
        border-radius: 50%;
        overflow: hidden;
        height: 72px;
        width: 72px;
        min-width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        .image {
          width: 100%;
          height: 100%;
        }
      }
    }
    .project-badges {
      text-align: center;
      .project-badges-text {
        font-size: 14px;
        font-weight: 500;
      }
      ul {
        display: flex;
        margin: 0 0 8px 0;
        justify-content: center;
        align-items: center;
        .badge-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 8px;
          .badge-image {
            width: 44px;
            height: 44px;
            .image {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  .project-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    .project-image-arrow{
      transition: all 0.2s ease-in-out;
      .icon-arrow{
        transition: all 0.2s ease-in-out;
      }
    }
    &:hover {
      .project-image-arrow {
        padding: 12px 18px;
        .icon-arrow {
          width: 40px;
        }
      }
    }
    .project-image-arrow {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      background-color: #222;
      opacity: 0.8;
      padding: 12px 0;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      &.back {
        left: 0;
      }
      &.forward {
        right: 0;
      }
      .icon-arrow {
        width: 0;
        height: 40px;
        fill: #fff;
      }
    }
    .carousel-item-project-media {
      width: 100%!important;
    }
    video, img {
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      box-shadow: 0px 3px 8px 0px rgbA(0, 0, 0, 0.1);
      border-radius: 6px;
    }
  }
}