.label-sub-text {
    font-size: 12px;
    padding-bottom: 5px;
  }
  .email-row {
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .email-verified {
    font-size: small;
    display: flex;
    justify-content: center;
  }
  .skip-verify-text {
    margin: 0 0 0 4px;
  }