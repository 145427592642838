@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.google-settings-container {
    .google-container {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin: 20px auto;
    }
    .google-settings-section {
      width: 100%;
      background-color: #fff;
      padding: 0 20px 20px;
      margin: 0 0 50px 0;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
      .google-sync-section {
        padding: 20px 0;
      }
      .google-sync-button-container {
        margin-top: 20px;
        text-align: center;
    
        .google-sync-button {
          background-color: $primary-color;
          color: #ffffff;
          border: none;
          border-radius: 4px;
          width: 100%;
    
          &:disabled {
            background-color: $secondary-color;
          }
        }
      }
    }
    
    select {
      display: block;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .gcal-warning {
      padding: 4px 8px;
      margin-bottom: 8px;
      background-color: red;
      color:white;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  