@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');

.pantry-container {
  position: absolute;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  .pantry-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .highlight {
    position: absolute;
    top: 5%;
    left: -20%;
    width: 70%;
  }
  .animated {
    transition: all 0.8s ease-out;
  }
  .hide-top {
    opacity: 0;
    transform: translateY(-100vh);
  }
  .hide-right {
    opacity: 0;
    transform: translateX(100vw);
  }
}