@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,900&display=swap');

.canvas-container {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #a2a2a2;
  z-index: 125;
  .check-in-button {
    position: absolute;
    bottom: 12px;
    left: 48px;
    padding: 10px 18px;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    background-color: #23d2e2ee;
    box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
    color: #fff;
    cursor: pointer;
    z-index: 201;
    &:hover {
      opacity: 0.95;
    }
  }
  
  .back-home-button {
    position: absolute;
    bottom: 6%;
    left: calc(50% - 100px);
    width: 200px;
    z-index: 200;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    animation: back-home 1s infinite alternate ease-in;
    .back-home-text {
      font-size: 24px;
      color: #fff;
    }
    .back-home-logo {
      width: 38px;
      height: 38px;
      path {
        stroke: #fff;
      }
    }
    &:hover {
      opacity: 70%;
    }
    @keyframes back-home {
      from { transform: translateY(0px); }
      to { transform: translateY(18px); }
    }
    @media screen and (max-width: $mobile-width) {
      bottom: 100px;
    }
  }

  .clickable-item {
    z-index: 150;
    position: absolute;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    .clickable-item-image {
      width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      object-fit: contain;
    }
    .reflection-anim {
      position: absolute;
      top: 0;
      left: 0;
      width: 10%;
      height: 38.2%;
      top: 40%;
      left: 55%;
      transform: skewY(30deg) skewX(-30deg);
      animation: reflection 3s infinite alternate ease-in-out;
      .reflection-anim-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffffee;
      }
      .reflection-anim-2 {
        position: absolute;
        width: 30%;
        height: 100%;
        background-color: #ffffffee;
        transform: translateX(500%);
      }
    }

    @keyframes reflection {
      from { transform: skewY(30deg) skewX(-30deg) translateX(0); }
      100% { transform: skewY(30deg) skewX(-30deg) translateX(20px); opacity: 0.3; }
    }
  }
  .rotation {
    transform-origin: 50% 0%;
    animation: rotation 3s infinite linear;
  }
  @keyframes rotation {
    from { transform: rotate(0deg); }
    25% { transform: rotate(2deg); }
    75% { transform: rotate(-2deg); }
    to { transform: rotate(0deg); }
  }
  
  .pulsate {
    animation: pulsate 1.5s ease-in-out infinite;
  }

  @keyframes pulsate {
    0% { transform: skewY(20deg) skewX(-20deg) scale(0.97); }
    50% { transform: skewY(20deg) skewX(-20deg) scale(1.03); }
    100% { transform: skewY(20deg) skewX(-20deg) scale(0.97); }
  }
  
  .square-pulsate {
    animation: square-pulsate 1.5s ease-in-out infinite;
  }

  @keyframes square-pulsate {
    0% { transform: skewY(30deg) skewX(-30deg) scale(0.97); }
    50% { transform: skewY(30deg) skewX(-30deg) scale(1.03); }
    100% { transform: skewY(30deg) skewX(-30deg) scale(0.97); }
  }
  
  .no-skew-pulsate {
    animation: no-skew-pulsate 1.5s ease-in-out infinite;
  }

  @keyframes no-skew-pulsate {
    0% { transform: scale(0.95); }
    50% { transform: scale(1.05); }
    100% { transform: scale(0.95); }
  }
  

  .clickable-svg {
    z-index: 150;
    position: absolute;
    pointer-events: none;
    .unclickable-svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      object-fit: contain;
    }
    .clickable-svg-cover {
      position: absolute;
      top: 0;
      right: 0;
      polygon {
        pointer-events: auto;
      }
    }
  }
  .floor {
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    animation: blink 2s infinite linear;
  }
  @keyframes blink {
    from { opacity: 0.2; }
    50% { opacity: 0.6; }
    to { opacity: 0.2; }
  }
  .floor-hide {
    opacity: 0;
    transform: translate(100vw, -57.7vw);
  }
  .static-item {
    z-index: 150;
    position: absolute;
    cursor: pointer;
    .static-item-image {
      width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      object-fit: contain;
    }
  }

  .sidebar-container {
    .sidebar-close-button {
      display: none;
      background-color: #fff;
      height: 48px;
      width: 48px;
      top: 8px;
      right: 8px;
      position: absolute;
      border-radius: 2px;
      padding: 8px;
      border: 0;
      cursor: pointer;
      svg{
        height: 100%;
        width: 100%;
        .real-path{
          fill: $primary-color;
        }
      }
      &:hover{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
      }
      &:active{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);
      }
    }

    .sidebar-backdrop {
      top: 0px;
      left: 0px;
      height: 100vh;
      width: 100vw;
      background-color: rgb(0, 0, 0, 0.30);
      position: fixed;
      z-index: -1;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    .show-backdrop {
      opacity: 1;
      z-index: 500;
    }
  
    .sidebar-content-container {
      position: fixed;
      top: 0px;
      right: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50vw;
      height: 100vh;
      background-color: #fff;
      z-index: 500;
      border-radius: 12px 0 0 12px;
      transition: all 0.3s ease-in-out;
      transform: translateX(50vw);
      .sidebar-scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        width: 100%;
        padding: 50px;
      }
    }
    .sidebar-content-container ::-webkit-scrollbar { 
      display: none;
    }
    .show {
      transform: translateX(0);
    }
    
    .sidebar-title {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      margin: 0 0 20px;
    }
    
    .sidebar-subtitle {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      margin: 0 0 20px;
      color: #666;
    }
    
    .sidebar-desc {
      width: 100%;
      font-size: 20px;
      font-weight: 400;
      margin: 0 0 20px;
      line-height: 28px;
    }

    .sidebar-video {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 12px 0;
      iframe {
        width: 100%;
        min-width: 300px;
        min-height: 300px;
        aspect-ratio: 16 / 9;
        border-radius: 12px;
      }
    }
    .sidebar-image {
      margin: 12px 0;
      width: 100%;
      object-fit: contain;
      max-height: 800px;
      border-radius: 20px;
    }
    .sidebar-button {
      padding: 12px 18px;
      border: 0;
      border-radius: 8px;
      font-size: 17px;
      font-weight: 500;
      background-color: #23d2e2;
      box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
      color: #fff;
      cursor: pointer;
      margin: 12px;
      &:hover {
        opacity: 0.9;
      }
    }
    @media screen and (max-width: $mobile-width) {
      .sidebar-content-container {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100vw;
        height: 100vh;
        transform: translateY(-100vh);
        opacity: 0;
        border-radius: 0;
      }
      .show {
        opacity: 1;
        transform: translateY(0);
      }
      .sidebar-close-button {
        display: flex;
      }
    }
  }

  .hide {
    display: none;
  }
}

.canvas-toast {
  border-radius: 12px;
}