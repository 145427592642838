@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.institutions-comp{
  // *{border: 1px solid black;}
  .users-container{
    display: flex;
    .users-container-left{
      width: 140px;
      margin: 0 20px 0 0;
      .user-sort{
        li{
          border-radius: 3px;
          margin: 0 0 4px;
          padding: 8px;
          cursor: pointer;
        }
        li:hover,
        .active{ 
          background-color: $primary-color; 
          color: #FFF;
        }
      }
    }
    .users-container-center{
      flex: 1;
      display: flex;
      flex-direction: column;
      .users-container-top{
        display: flex;
        .users-search{
          flex: 1;
          padding: 0 20px 0 0;
          form{
            height: 40px;
            display: flex;
            select{
              border-color: rgba(220,220,220,1);
              margin: 0 0 0 -1px;
            }
            .button-element {
              margin: 0 0 0 8px;
              max-width: 80px;
            }
          }
        }
        .users-add{
        }
      }
      .users-main{
        margin: 20px 0 0 0;
        background-color:#FFF;
        border-radius: 3px;
        overflow: hidden;
        table{
          width: 100%;
          border-collapse:collapse;
          thead{
            background-color: #f3f7fc;
          }
          tr{
            &:not(.header){
              cursor: pointer;
              &:hover{
                box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
              }
            }
            width: 100%;
            th,td{
              text-align: center;
              height: 48px;
            }

            .username-info{
              display: flex;
              align-items: center;
              img,.svg-container {
                margin: 0 8px;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: rgba(220,220,220,1);
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
              }
              .svg-container{
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                  height: 24px;
                  width: 24px;
                  .real-path{
                    fill: #555;
                  }
                }
              }
            }
          }
        }
        .no-results{
          padding: 20px;
        }
        .users-main-footer{
          height: 56px;
          padding: 0 8px;
          background-color: #f3f7fc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button-element {
            width: 80px;
          }
        }
        .users-button-load-div{
          display: flex;
          flex-direction: row;
          height: 56px;
          padding: 0 8px;
          align-items: center;
        }
      }
    }
  }
}