@import '../../css/styles/colors';
@import '../../css/styles/variables';

$navi-width: 220px;

.navi-main::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.navi-main{
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: $secondary-color;
  height: 100%;
  width: $navi-width;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  @media screen and (min-width: $desktop-width) {
    &.toggle-width-active{
      overflow: auto;
      width: 60px;
    }
  }

  .width-toggle{
    display: none;
    top: 0px;
    right: 0px;
    position: absolute;
    z-index: 25;
    height: 100%;
    width: 4px;
    cursor: pointer;
    &:hover{
      background-color: #FFF;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,.5);
    }
  }
  @media screen and (min-width: $desktop-width) {
    .width-toggle{
      display: block;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .navi-main{
    padding-top: 48px;
    position: absolute;
    top: 0px;
    overflow: auto;
    z-index: 99;
    height: 100vh;
    min-height: calc(100vh - calc(100vh - 100%));
    left: -1 * $navi-width;
    transition: left 0.3s;
    &.open{
      left: 00px;
    }
  }
}