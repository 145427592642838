  .user-info-tab {
    .user-info-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h3, p {
        margin: 0;
      }
      .bulk-import {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
      margin-bottom: 24px;
    }
    .label-sub-text {
      font-size: 12px;
      padding-bottom: 5px;
    }
    .email-row {
      padding: 4px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .email-verified {
      font-size: small;
      display: flex;
      justify-content: center;
    }
    .skip-verify-text {
      margin: 0 0 0 4px;
    }
  }