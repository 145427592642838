.create-user-pdf-us {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 816px;
  height: 1054px;
  background-color: #fff;
  color: black;
  padding: 60px 90px;
  // font-family: 'Times New Roman', Times, serif;
  letter-spacing: 0.01px;
  .header {
    margin-top: -35px;
  }
  .agreement-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    .title {
      margin: 18px;
      // font-weight: 800;
      font-size: 16px;
    }
    .subtitle {
      .text {
        font-size: 15px;
        margin-top: 15px;
        // font-weight: 500;
        text-align: justify;
      }
    }
    .tables {
      width: 100%;
      .table-title {
        font-size: 13px;
        font-weight: 700;
        margin: 12px 0;
      }
      .table {
        width: 100%;
        border: 1px solid black;
        font-size: 13px;
        text-align: center;
        .table-row {
          display: flex;
          flex-direction: row;
          width: 100%;
          border-bottom: 1px solid black;
          .field {
            display: flex;
            flex-direction: row;
            width: 100%;
            .field-name {
              padding-left: 5px;
              width: 50%;
              height: 20px;
              line-height: 20px;
              border-right: 1px solid black;
              text-align: start;
              &.address {
                height: 40px;
                display: flex;
                align-items: center;
              }
            }
            .field-value {
              padding-right: 5px;
              height: 20px;
              line-height: 20px;
              margin-left: auto;
              max-width: 49%;
              &.address {
                height: 40px;
                max-width: 48%;
                text-align: right;
                line-height: 16px;
                display: flex;
                align-items: center;
              }
            }
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
    .agreements {
      .subsection-title {
        margin-top: 24px;
        font-size: 15px;
      }
      .texts {
        padding-left: 12px;
        .row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .tick {
            margin-right: 10px;
            margin-top: 4px;
          }
          .text {
            font-size: 15px;
            margin-bottom: 0;
            text-align: justify;
          }
        }
      }
    }
    .signature-ctn {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .signatures {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        .signature {
          display: flex;
          flex-direction: column;
          align-items: left;
          width: 300px;
          .name-and-signature {
            display: flex;
            flex-direction: row;
            align-items: left;
            padding-top: 12px;
            font-size: 15px;
            .subtitle {
              margin-right: 8px;
            }
            .date {
              text-decoration: underline;
            }
            .guardianName {
              margin-right: 12px;
              text-decoration: underline;
            }
            img {
              height: 50px;
            }
            .signature {
              margin: 20px 0 0 0;
              width: 73px;
            }
          }
          .text {
            width: 100%;
            border-top: 1px solid black;
            padding-top: 12px;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
          }        
        }
      }
    }
    .verify {
      margin-top: 30px;
      margin-left: auto;
      font-size: 15px;
    }
  }
  .footer {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 15px;
      margin: 0
    }
  }
}